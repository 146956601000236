import Layout from "../components/layout"

import ComposersMDX from "../content/composers"

import React from "react"

export default () => {
  return (
    <Layout title="Composers">
      <ComposersMDX />
    </Layout>
  )
}
