import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Col, Row } from "react-bootstrap";
import Content from "../components/content";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <div className="alternating-sections">
      <Content maxWidth="1127px" mdxType="Content">
        <h1>{`Johann Sebastian Bach`}</h1>
        <br />
        <p>{`Born 31 March 1685 | `}<span className="emphasis">{`Track 2`}</span></p>
        <p>{`Johann Sebastian Bach lived between 1685-1750 and was born in Eisenach, Germany, and died in Leipzig. He composed during the Baroque Period, which was approximately from 1600 to 1750. Seven generations of Bach’s were professional musicians! He was a master of counterpoint, the texture resulting from the combining of individual melodic lines and harmony. He was married twice and had 20 children and wrote orchestra, chamber, keyboard and choral music. “Inventio I” (track 2), is an example of counterpoint. He was the most famous composer ever, until Mozart, and was very prolific. Music at this time had a very fancy, decorated style.`}</p>
      </Content>
      <Content maxWidth="1127px" mdxType="Content">
        <h1>{`Ludwig Van Beethoven`}</h1>
        <br />
        <p>{`Born 16 December 1770 | `}<span className="emphasis">{`Track 1, 16, and 23`}</span></p>
        <p>{`Ludwig Van Beethoven lived between 1770-1827 and was born in Bonn, Germany. He composed and wrote music in the Romantic Period, which is about how the music made you feel. It was dramatic and the orchestra had more instruments, opera was more important and stories were of the supernatural, The piano became very popular and at first, only royalty had pianos. Bartolomeo Cristofori, an Italian, invented the “pianoforte” with more keys than the harpsichord, and it had three pedals that could sustain tones. Beethoven was poor and had a mean father, but at 16 went to play for Mozart, who was 30 at the time, who was very impressed. At 26 Beethoven became deaf but could still “hear” music in his head and continued to compose music. He composed orchestral, chamber, keyboard, choral, and opera music. He is one of the most important composers in the history of music! Notable Works include: nine symphonies, “Moonlight Sonata” (track 23), “Für Elise” (track 16), and “Missa Solemnis.” His famous works are” “Symphony No. 3 in E Flat (Eroica),” “Violin Concert in D,” “Symphony No. 5 in C Minor,” “Piano Concerto No. 5 in E Flat (Emperor),” and “Symphony No.9 in D Minor.” He died of pneumonia in 1827, in the midst of a violent thunderstorm. All of Vienna closed down for his funeral`}</p>
      </Content>
      <Content maxWidth="1127px" mdxType="Content">
        <h1>{`Johannes Brahms`}</h1>
        <br />
        <p>{`Born 7 May 1833 | `}<span className="emphasis">{`Track 8`}</span></p>
        <p>{`He lived between 1833-1897 in Germany. Johannes Brahms was born May 7, 1833 in Hamburg, Germany and died April 3, 1897 in Vienna. He composed during the Romantic Period, and his style was like Beethoven’s. His music was for: orchestra, chamber groups, keyboard, choral groups, and songs. As a young child, Brahms had a difficult life. Eduard Marxsen recognized his extraordinary musical gift and taught him for free. By 13, Brahms was being paid for his playing. He became friends with Clara and Robert Schumann at 20. Robert Schumann was put into hospital for his mental health and Brahms helped popularize his music. Robert died in 1856, but Clara, also a musician, and Brahms maintained a lifelong friendship. Brahms never married. At 39, Brahms moved to Vienna and composed two symphonies, a “Violin Concerto in D,” and others. “Waltz in Ab Major, Op. 39 No.15,” track 8, is very famous. “The Brahms Lullaby” is famous to everyone as the typical song Moms use to put their babies to sleep. Brahms was supportive of young composers as Dvorak and Grieg. When Clara died, Brahms attended her funeral, caught a cold, and died at 64. He is often called the last of the great Classics.`}</p>
        <p>{`Dedicated to my mom, Joan Runningen`}</p>
      </Content>
      <Content maxWidth="1127px" mdxType="Content">
        <h1>{`Frédéric Chopin`}</h1>
        <br />
        <p>{`Born 22 February 1810 | `}<span className="emphasis">{`Track 3`}</span></p>
        <p>{`Fryderyk Franciszek Szopen lived between 1810-1849 and was born near Warsaw, in Zelazowa Wola, Poland. He died October 17, 1849, in Paris. His music was composed during the Romantic Period. Chopin and Liszt are the most famous composers of this time. Chopin’s music is almost entirely for the piano.  Chopin  made a significant contribution to the development of modern piano technique and created almost 200 works for the piano. He wrote: mazurkas, waltzes, polonaises and others. “Waltz in D Flat Major” (track 3) is in this selection. “Op. 25, No. 9 Butterfly Etude” is also famous. Chopin was famous at nine, went to Vienna at 20, and later went to Paris, where he performed and taught. Schumann helped make Chopin’s music famous in Europe; he said, “Hats off gentleman, a genius!” Chopin died at 39 of tuberculosis.`}</p>
        <p>{`Dedicated to my piano teacher, Hazel Van Dyk`}</p>
      </Content>
      <Content maxWidth="1127px" mdxType="Content">
        <h1>{`Muzio Clementi`}</h1>
        <br />
        <p>{`Born 24 January 1752 | `}<span className="emphasis">{`Track 6`}</span></p>
        <p>{`Muzio Filippo Vincenzo Francesco Saverio Clementi lived between 1752-1832 and was born in Rome, Italy. His father encouraged his music, and at age 14 he gave his first concert; at 20 he gave a concert in London. He was born four years before Mozart, and lived five years longer than Beethoven. He was at the end of the Classical Period. He owned a piano manufacturing company and a music publishing house. Clementi was one of the first composers to write piano music, not harpsichord, which produced sound differently. Clementi was one of the lead composers of sonatinas, which have three movements: fast, moderate, and lively.`}</p>
      </Content>
      <Content maxWidth="1127px" mdxType="Content">
        <h1>{`Claude Debussy`}</h1>
        <br />
        <p>{`Born 22 August 1862 | `}<span className="emphasis">{`Track 5`}</span></p>
        <p>{`Achille-Claude Debussy lived between 1682-1918 and was born in Saint-Germain-en-Laye, France. Claude Debussy was born August 22, 1862 in Saint Germain-en-Laye, France and died March 25, 1918 in Paris. His music was in the Modern Period. He wrote for: orchestra, chamber music, ballet, keyboard, choral, opera, and songs. He wanted his music to be totally different from Wagner and strict forms. Debussy’s music was influenced by the artists of Monet, Manet and Renoir. Debussy pictured nature in his music. “Clair de Lune,” track 5, portrays the light of the moon, “The Afternoon of a Fawn” portrays a young deer. Debussy was born to poor parents. His aunt recognized his talent and sent him to study at the Paris Conservatory. At 22, he won the “Prix de Rome Prize” to study in Rome. He returned to Paris. He married twice and had one daughter. He died of cancer at 56.`}</p>
      </Content>
      <Content maxWidth="1127px" mdxType="Content">
        <h1>{`Edward Mac Dowell`}</h1>
        <br />
        <p>{`Born 18 December 1860 | `}<span className="emphasis">{`Track 18`}</span></p>
        <p>{`Edward Alexander MacDowell lived between 1860-1908 and was born in New York, NY. His music was in the Romantic Period and he wrote for: keyboard, orchestra, choral, and songs. MacDowell was a prolific composer. “To a Wild Rose” from “Woodland Sketches,” (track 18) is his most popular short piece. It’s tone is quiet and relaxing. His “Second Piano Concerto in D Minor” is his world-famous larger work. Grieg, Schumann and Liszt influenced his music. All his works have a literary or pictorial association. He was one of the first American musicians to have an international reputation. MacDowell started piano at eight, at 15 he studied in Paris, at 17 he went to Germany, and at 20 he devoted himself to composing. He married Mirian. At 36, he became the first Professor of Music at Columbia University. They moved to their New Hampshire farm and his creativity flourished. He was an excellent piano teacher. They formed McDowell Colony on their Hill Chest Farms for musicians to live and work in the summer.`}</p>
        <p>{`Dedicated to a very dear friend, Gloria Pavy`}</p>
      </Content>
      <Content maxWidth="1127px" mdxType="Content">
        <h1>{`Albert Ellmenreich`}</h1>
        <br />
        <p>{`Born 10 February 1816 | `}<span className="emphasis">{`Track 21`}</span></p>
        <p>{`Albert Ellmenreich was born between 1816-1905 and lived in Karlsruhe, Germany. He was a German actor, writer, singer and composer. He died May 30 1905 in Lubeck, Germany. “Spinning Song” is his most famous work.`}</p>
      </Content>
      <Content maxWidth="1127px" mdxType="Content">
        <h1>{`Edvard Grieg`}</h1>
        <br />
        <p>{`Born 15 June 1843 | `}<span className="emphasis">{`Track 4`}</span></p>
        <p>{`Edvard Hagerup Grieg lived between 1844-1907 and was born in Bergen, Norway. His music was in the Romantic Period and was for orchestra, chamber music, keyboard, choral, and song. Grieg wrote 66 “Lyric Pieces” and 25 “Norwegian Folk Songs and Dances.” Grieg has been called “The Chopin of the North.” “Nocturne in C Major, Op. 54, No.4” was chosen for this selection because it reminds me of Grieg’s home in Troldhaugen, 20 minutes out of Bergen, and the fjord he saw out of his window as he composed music. In 2017, I heard the winner of the Grieg competition play this Nocturne in the concert hall of The Troldhaugen Museum. Grieg was interested in the piano at five and liked to compose. He studied in Germany and gave concerts in Bergen and Copenhagen. He married Nina, a singer, and they settled in Oslo, where he became a renowned teacher and conductor. Grieg had a happy and prosperous life. He wrote the patriotic “Two Norwegian Melodies” for strings and orchestra, based on actual folk songs. “Piano Concerto in A Minor” 1868, “The Song of Norway,” “Holberg Suite,” and “Peer Gynt Suites” (author Henrik Ibsen) 1876, Nos. 1 & 2, are famous. “Morning” is a song from “Peer Gynt” and is played by many young pianists today. Upon his death, on September 4, 1907, he was honored with a state funeral as Norway’s greatest composer.`}</p>
        <p>{`Dedicated to my Norwegian Family`}</p>
      </Content>
      <Content maxWidth="1127px" mdxType="Content">
        <h1>{`George Frideric Handel`}</h1>
        <br />
        <p>{`Born 23 February 1685 | `}<span className="emphasis">{`Track 13 & 22`}</span></p>
        <p>{`Georg Friedrich Händel lived between 1685-1759 and was born in Halle, Germany. His music was composed during the Baroque Period and for choral, opera, orchestra, chamber music, keyboard and songs. Both of my selections are from his “Messiah,” a story about Jesus, a very famous work. His Aunt Ana encouraged him in his music. Handel wrote a lot of music in London and became friends with kings. He is buried in the famous Westminster Abby Church in London.`}</p>
        <p>{`Dedicated to Weston Noble, musical conductor at my college, Luther College, Decorah, Iowa`}</p>
      </Content>
      <Content maxWidth="1127px" mdxType="Content">
        <h1>{`Joseph Haydn`}</h1>
        <br />
        <p>{`Born 31 March 1732 | `}<span className="emphasis">{`Track 15`}</span></p>
        <p>{`Franz Joseph Haydn lived between 1732-1809 and was born in Rohrau, Austria. He composed during the Classical Period, where form and structure were stressed more than expression.His compositional media is orchestra, chamber music, keyboard, choral, opera, and songs. Being very prolific, he wrote 100 symphonies. Haydn was poor but at eight he went to live in the palace of Prince Esterhazy. The harpsichord was the keyboard instrument of his time, where the strings are plucked by little quills. His “Surprise Symphony No. 94,” is very famous.`}</p>
      </Content>
      <Content maxWidth="1127px" mdxType="Content">
        <h1>{`Stephen Heller`}</h1>
        <br />
        <p>{`Born 16 May 1813 | `}<span className="emphasis">{`Track 17`}</span></p>
        <p>{`Stephen Heller lived between 1813-1888. Steven Heller was a pianist, teacher, and composer during the Romantic Period, when composers were concerned about how the music made you feel. It was very dramatic, used more instruments and would be loud, then very quick, very fast, then very slow. Operas became important. He was an influence for later Romantic composers like Bizet. Heller was almost forgotten at the time of his death. He was born in Budapest, Hungary on May 16, 1813 and died January 14, 1888 in Paris.`}</p>
        <p>{`Dedicated to my piano teacher, Hazel Van Dyk`}</p>
      </Content>
      <Content maxWidth="1127px" mdxType="Content">
        <h1>{`Jules Massenet`}</h1>
        <br />
        <p>{`Born 12 May 1842 | `}<span className="emphasis">{`Track 14`}</span></p>
        <p>{`Jules Émile Frédéric Massenet lived between 1842-1912. He was born in Montaud, France and died August 13, 1912 in Paris. He composed more than 30 operas in the Romantic Era. His most famous operas are: “Manon,” and “Werther.” He also composed oratorios, ballets, orchestral works, incidental music, piano pieces and songs. “Meditation,” track 14, is from the Opera “Thais.” “Thais” is a comedie lyrique in three acts and seven tableaux, 1894, and is often played as a duet with piano and violin. “Meditation” is a symphonic intermezzo written for solo violin and orchestra. The opera premiered in Paris in 1894. Massenet was married and had a daughter.`}</p>
        <p>{`Dedicated to my Georgia friends, Dr. Dana & Dennis Kersey`}</p>
      </Content>
      <Content maxWidth="1127px" mdxType="Content">
        <h1>{`Wolfgang Amadeus Mozart`}</h1>
        <br />
        <p>{`Born 27 January 1756 | `}<span className="emphasis">{`Track 9`}</span></p>
        <p>{`Wolfgang Amadeus Mozart lived between 1756-1791 and was born in Salzburg, Austria. The piano was invented in Italy 47 years before he was born. He composed music in the Classical Period, and with Haydn helped develop the symphony, sonata, and concerto forms of music. Mozart gave his first concert at six in Vienna, and at nine wrote his first symphony. Living until only 35, he still wrote 41 symphonies (three in six weeks!) and 27 piano concertos, and several operas, a true prodigy! “The Magic Flute” is his most popular opera; the music is in the 3rd movement of Symphony No. 40 in G minor. He wrote “Requiem in D minor,” “Eine Kleine Nachtmusik,” and “Don Giovanni.” Today, his most popular works are” “Concerto for Piano and Orchestra No. 21 in C,” (track 9), “Serenade No. 13 in G (A Little Night Music),” “Symphony No.41 in C (Jupiter),” and “Concerto for Clarinet Orchestra in A.” Mozart astonished the courts of Europe.`}</p>
      </Content>
      <Content maxWidth="1127px" mdxType="Content">
        <h1>{`Johann Pachelbel`}</h1>
        <br />
        <p>{`Born 1 September 1653 | `}<span className="emphasis">{`Track 20`}</span></p>
        <p>{`Johann Pachelbel lived between 1653-1706 and was born in Germany. He composed during the Baroque historical period. Keyboard, chamber music, and choral music were his areas of composition. “Canon in D” is one of his most famous works and is often played at weddings.`}</p>
      </Content>
      <Content maxWidth="1127px" mdxType="Content">
        <h1>{`Franz Schubert`}</h1>
        <br />
        <p>{`Born 31 January 1797 | `}<span className="emphasis">{`Track 12`}</span></p>
        <p>{`Franz Peter Schubert lived between 1797-1828 and was born in Himmelpfortgrund, Austria (near Vienna). He was born during the early Romantic Period and had many brothers and sisters. He grew up poor, but only wanted to compose music, yet he never made a fortune. He frequently heard a song in his mind and would write it on a tablecloth. He wrote: songs, orchestra, chamber, keyboard, choral, and opera music. He wrote, “Ave Maria,” “The Unfinished Symphony No. 8 in B Minor,” and “Trout Quintet.” He wrote over 600 songs. Chamber music is written for a very small group of instruments to be played in a small “music chamber” or music room. Although he composed symphonies and quartets, his “Lieder” romantic songs based on poems, were unique. At 19 he gave up teaching to make a living as a composer; by 23 he had composed over 500 works. He died at 31 of a venereal disease and was buried close to his hero, Beethoven. Schubert’s most popular works are: “Symphony No. 9 in C (Great),” “Symphony No. 8 in B Minor (unfinished),” “Piano Quintet in A (Trouch),” “An Sylvia”, “Erlkonig,” and other “Lieder.”`}</p>
      </Content>
      <Content maxWidth="1127px" mdxType="Content">
        <h1>{`Robert Schumann`}</h1>
        <br />
        <p>{`Born 8 June 1810 | `}<span className="emphasis">{`Track 10`}</span></p>
        <p>{`Robert Schumann lived between 1810-1856 and and was born in Zwickau, Saxony, and died July 29, 1856, in Endenich, Germany. His music was in the Romantic Period. He played “musical portraits” of his friends. He married Clara Wick, daughter of his piano teacher, also a pianist. Schumann injured his fourth finger and had to give up playing piano, but became a great composer and music critic. Clara played his music and concerts all over Europe. “The Merry Farmer,” is on track 10. Suggested listening is: “The Spring Symphony” (Symphony in Bb Major, Op. 38) and “Kinderszenen” (Scenes from Childhood-”Traumerei,” and Playing Tag-”Haschman”).`}</p>
      </Content>
      <Content maxWidth="1127px" mdxType="Content">
        <h1>{`Johann Strauss Il`}</h1>
        <br />
        <p>{`Born 25 October 1825 | `}<span className="emphasis">{`Track 7`}</span></p>
        <p>{`Johann Strauss II, lived between 1825-1899, and was born in Vienna, Austria, and died June 3, 1899. He became known as “The Waltz King” because he composed 400 waltzes, 300 polkas, gallops, marches, and other dances, and many had sweeping melodies and rich orchestration. “The Blue Danube” and “Tales from the Vienna Woods,” track 7, are two of his famous waltzes. Strauss was from a famous musical family, but his father dissuaded him from music. However, by 19 Johann Jr. formed his first orchestra, which became an instant success. Eventually, Strauss ran six orchestras! In 1872 he played “The Blue Danube” in the US for $100,000. He returned to Vienna a millionaire. Strauss was married three times. In 1874 he wrote, “Die Fledermaus,” an operetta about a bat. A friend, Richard Strauss, no relation, called him “the last of the composers who worked from spontaneous inspiration.”`}</p>
        <p>{`Dedicated to my mom, Joan Runningen`}</p>
      </Content>
      <Content maxWidth="1127px" mdxType="Content">
        <h1>{`Pyotr Ilych Tchaikovsky`}</h1>
        <br />
        <p>{`Born 7 May 1840 | `}<span className="emphasis">{`Track 11`}</span></p>
        <p>{`Pyotr Ilyich Tchaikovsky lived between 1840-1893 and was born in Votkinsk, Ruissa. He died in St. Petersburg in 1893. “The Italian Folk Song,” track 11, is appealing to the heart and typical of Tchaikovsky’s music. He composed during the Romantic Period but the Nationalistic Era was beginning to develop, a time that the arts focused on the love of one’s country. Composers used native folk songs for the basis of their great compositions. Motivated after hearing Tchaikovsky’s music, Madame Nadejda Von Meck became his pen pal and gave him a lot of money to pursue his composing, which he did. They never met. His mother died when he was 14, and Madame Von Meck helped fill his void. He came to America and conducted his own “1812 Overture” at the opening of Carnegie Hall in New York in 1891. His popular works are: “Fantasy Overture to Romeo and Juliet.” ‘1812 Overture,” “The Nutcracker Ballet,” “Swan Lake,” and “Sleeping Beauty” ballets, and “Symphony No. 6 in B Minor (Pathetique),” “Eugene Onegin,” and “Queen of Spades” operas.`}</p>
      </Content>
      <Content maxWidth="1127px" mdxType="Content">
        <h1>{`Traditional American Melody,“Virginia Harmony” 1779`}</h1>
        <br />
        <p><span className="emphasis">Track 19</span></p>
        <p>{`John Newton, in the 1700s, wrote the words to “Amazing Grace,” based on Ephesians 2:8 in the Bible. Conscripted into the Royal Navy, then later a slave ship captain, it was a violent storm that helped him find his spiritual conversion. He left the sea to pursue his religious vocation as a clergyman. He later joined forces with the campaign to end the slave trade. Newton eventually became an outspoken abolitionist, one who supported the end to slavery. Newton’s message is, “Through God’s great mercy, forgiveness and redemption is possible and our souls can be saved from despair.” It is one of the most popular Christian songs of all time and is sung about 10,000,000 times a year!`}</p>
        <p>{`Dedicated to my father, Ray Runningen`}</p>
      </Content>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      